$red: #f00 !default;
$xl: 32px;

.quiz .Div,
.results .Div,
.header .Div {
  display: flex;
  justify-content: center;
}

.quiz__image img {
  border-radius: 50%;
}

.header__image img {
  display: block;
  max-width: 400px;
  width: 100%;
  height: 100%;
}

.header__title {
  text-align: center;
}

.header__title h1 {
  margin: 0;
  text-align: center;
}

.quiz__image img {
  max-width: 600px;
  width: 175%;
  height: 175%;
}

@media screen and (max-width: 500px) {
  .quiz__image img {
    margin: -50px auto;
  }
}

.results__image img {
  max-width: 600px;
  width: 100%;
  height: 100%;
}

.quiz__title {
  flex: 1 1 100%;
  margin-bottom: 0;
}

h2.quiz__title {
  font-size: 100%;
  color: #999;
}

.quiz__answers {
  display: flex;
}

.quiz__answer {
  margin-right: 8px;
  background: #e00;
  border: 2px solid #e00;
  font-weight: bold;
}

.quiz__answer_white {
  background: #fff;
  border: 2px solid #00b;
  color: #00b;
}

.quiz__answers_correct_white .quiz__answer_white,
.quiz__answers_correct_red .quiz__answer_red {
  background: #00bb27;
  border: 2px solid #00bb27;
}

.results__numbers {
  font-size: $xl;
  font-weight: bold;
  border: 4px solid #5181b8;
  background: #5181b8;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 4*$xl;
  height: 4*$xl;
  line-height: 4*$xl;
  text-align: center;
}

.header .header__logo {
  display: flex;
  margin: auto;
  padding: 10px;
  height: 150px;
}

.header .header__shuffle {
  display: flex;
  margin: auto;
}
